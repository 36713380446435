.App {
  text-align: center;
}
.logo {
  width: 120px;
}

button {
  text-transform: none !important;
}

@media all and (max-device-width: 738px) {
  .logo {
    width: 80px;
  }
}

.menuVideoDialogue .MuiDialog-paper {
  margin: 0;
}
.surveypageWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.themeBGPattern {
  background: linear-gradient(90deg, #f5f7fd 19px, transparent 1%) center,
    linear-gradient(#f5f7fd 19px, transparent 1%) center, #b8b9be;
  background-size: 22px 22px;
}
.recharts-default-tooltip {
  overflow-y: auto;
}
