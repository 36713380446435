.singlePageSurveyWrapper {
  margin: 80px auto;
  min-height: 80vh;
}
.singlePageFormComponentWrapper {
  padding: 36px 80px 50px 80px;
}
.singlePageSurveyLabel {
  font-weight: 500 !important;
  font-size: 2rem !important;
  color: #181c4d !important;
}
.singlePageSurveyQuestionDivider {
  background: #d9d9d9 !important;
}

.singlePageSurveyOption {
  font-weight: 500 !important;
  font-size: 1.5rem !important;
}

.yesorno {
  border-radius: 5px;
  height: 51px;
  width: 164px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.photocapture {
  width: 462px;
  height: 211px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eff4ff;
  border-radius: 24px;
  border: 1px dashed #3a63f3;
  margin-top: 36px;
  cursor: pointer;
}

.singlepageSurveySelect > fieldset {
  border: none !important;
}
.singlePageSmiley {
  cursor: pointer;
  border-radius: 50%;
  width: 60px !important;
  height: 60px !important;
}

@media all and (max-device-width: 732px) {
  .singlePageSurveyWrapper {
    width: 98%;
    margin: 32px auto;
  }
  .singlePageFormComponentWrapper {
    padding: 36px 20px 50px 20px;
  }
  .photocapture {
    width: 96%;
  }
}
@media all and (max-device-width: 450px) {
  .singlePageSmiley {
    width: 40px !important;
    height: 40px !important;
  }
}
