.resultoverviewpageWrapper {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  overflow: hidden;
  gap: 30px;
  box-shadow: none !important;
}

.gridContainerWrapper {
  overflow: hidden auto !important;
}
.cardWrapper {
  padding: 12px;
  border-radius: 7px !important;
  box-shadow: none !important;
}

.mapGridItemWrapper {
  overflow-y: auto;
  height: 100%;
}

.leaflet-container {
  min-height: 230px;
  height: 100%;
  width: 100%;
  z-index: 0;
}
