.dashboardViewLogoWrapper {
  width: 100%;
  height: 100vh;
  /* background-color: black !important; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboardViewLogoWrapper > img {
  width: 150px;
}
