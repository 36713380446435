.dashboard-template-1 {
  /* background-image: url("../../../Assets/template-1.png"); */
  background-position: center;
  background-size: cover;
}

.dashboard-template-2 {
  /* background-image: url("../../../Assets/template-2.png"); */
  background-position: center;
  background-size: cover;
}

.dashboard-template-3 {
  /* background-image: url("../../../Assets/template-3.png"); */
  background-position: center;
  background-size: cover;
}

.dashboard-template-4 {
  /* background-image: url("../../../Assets/template-4.png"); */
  background-position: center;
  background-size: cover;
}

.powered {
  font-size: 1em;
  opacity: 0.7;
}
.formComponentWrapper {
  flex: 0.85;
  max-width: 1300px;
  width: 100%;
}
.formComponentProgressWrapper {
  flex: 0.15;
  width: 100%;
  max-width: 1300px;
  height: fit-content;
  border-top: 1px solid rgba(58, 99, 243, 0.58);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media all and (max-width: 900px) {
  .formComponentProgressWrapper {
    border-top: none;
    border-bottom: 1px solid rgba(58, 99, 243, 0.58);
  }
}
@media all and (max-device-width: 992px) {
  .dashboard-view-card {
    min-height: 60vh;
  }

  .dashboard-view {
    max-height: 90vh;
  }

  .logo {
    width: 80px;
    height: auto;
  }
}
