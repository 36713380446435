.surveyspageWrapper {
  min-height: 100vh;
  border: none;
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 20px;
  position: relative;
}
.accounttypespecifier {
  position: absolute;
  top: 3%;
  right: 4%;
  background-color: #d4ece1;
  padding: 3px;
  color: #37a051;
  border-radius: 5px;
  font-weight: 900;
  cursor: pointer;
}
.surveysPageUpperCard {
  min-height: 100px;
  border-radius: 10px;
  padding: 22px 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  background-color: #ffffff;
}
.surveysPageUpperCardTitle {
  font-weight: 500 !important;
  font-size: 1.875rem !important;
}
.surveySearchBar {
  width: 100%;
  border: 1px solid #4b7be5;
  border-radius: 5px;
  height: 34px;
}

.createSurveyGridItemWrapper {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  min-height: 150px;
}
.surveyGridItemWrapper {
  background-color: #fff !important;
  /* width: 100%; */
  height: 100%;
  border-radius: 10px;
  padding: 25px 16px 10px 34px;
}
.SurveyGridItemTitleSection {
  display: flex;
  justify-content: space-between;
}
.surveyGridItem {
  padding-bottom: 32px !important;
}

@media all and (max-device-width: 600px) {
  .surveyspageWrapper {
    padding: 8px;
  }
  .surveysPageUpperCard {
    display: block;
    padding: 20px 12px;
  }
  .surveysPageUpperCardTitle {
    font-size: 1.575rem !important;
  }
  .surveySearchBar {
    height: 28px;
    margin-right: 0px;
  }
  .surveySearchBar > div {
    height: 28px;
  }
  .surveyGridItem {
    padding-left: 0 !important;
  }
  .surveyGridItemWrapper {
    padding: 16px;
  }
}
