.label {
  font-size: 1.5em;
  margin-bottom: 58px;
}

.radio-label {
  border-color: var(--primary-color);
  color: #fff;
  border-radius: 5px !important;
  display: flex;
  width: 100%;
}

.details-label {
  font-size: 1.5em;
}

.details-input fieldset {
  border: none;
  height: 35px !important;
  padding: 0;
}

/* .height fieldset {
} */

.survey-input fieldset {
  height: 35px !important;
  padding: 0;
  border: none;
}
.react-select-input {
  background-color: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 50px;
}

.css-1s2u09g-control {
  border: none !important;
  background-color: initial !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color);
}

.btn-primary:active {
  background-color: var(--primary-color) !important;
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:focus {
  color: #fff !important;
  box-shadow: none !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.form-control:focus {
  color: #cccccc;
  outline: none;
  box-shadow: none;
  border-color: none !important;
  background-color: var(--tertiary-color);
}

.form-select:focus {
  box-shadow: none !important;
}

::placeholder {
  color: #cccccc !important;
  font-weight: 400;
}

.submitted {
  color: #676ba8;
  font-size: 2em;
}

.next-button {
  border: 1px solid #5b61f3;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 1.35em;
  background-color: #fff !important;
  color: var(--primary-color) !important;
}

::-webkit-scrollbar {
  width: 10px;
}
