.protectedrouteWrapper {
  display: flex;
  background-color: #eff0f4;
  overflow-y: hidden;
  height: 100vh;
}
@media all and (max-device-width: 600px) {
  .protectedrouteWrapper {
    display: block;
    /* background-color: #eff0f4;
    overflow-y: hidden;
    height: 100vh; */
    height: auto;
    padding-bottom: 60px;
  }
}
