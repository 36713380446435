.resultresponsepage {
  height: calc(100%);
  overflow-y: hidden;
  width: 100%;
  display: flex;
  gap: 12px;
}
.resultresponsepageWrapper {
  width: 100%;
  height: calc(100%);
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: hidden;
  float: right;
}
.totalResponseOverviewWrapper {
  background: #ffffff;
  border-radius: 9px;
  padding: 12px 16px 20px 10px;
  display: flex;
  justify-content: space-between;
}
.totalResponseButtonWrapper {
  border-radius: 5px;
  min-height: 40px !important;
  max-height: 40px !important;
  box-shadow: none;
  display: flex;
  padding: 4px;
  max-width: 30px;
  cursor: pointer;
  border: 1px solid #4b7be5;
}
.responseSearchbar > div {
  height: 40px !important;
  border: 1px solid #4b7be5 !important;
}
.responseSearchbar > div > input {
  height: 40px !important;
}
.responseSearchbar {
  height: 40px !important;
}
.responseSearchbar fieldset {
  border: none !important;
  height: 40px;
  top: 0;
}
.resultresponsepageTableContainer {
  height: 100%;
}
.responseTableHeaderCell {
  font-size: 1.125rem !important;
  font-weight: 600 !important;
  color: #3f3d56 !important;
  background-color: #fff;
  padding: 18px 12px 11px 12px !important;
  margin-bottom: 12px !important;
}

.responseTableHeaderFirstCell {
  border-radius: 9px 0px 0px 9px;
  align-items: flex-start !important ;
}

.responseTableBodyFirstCell {
  border-radius: 9px 0px 0px 9px;
  align-items: flex-start !important ;
}

.responseTableBodyWrapper:before {
  content: "@";
  display: block;
  line-height: 10px;
  text-indent: -99999px;
}

.responseTableBodyCell {
  border-bottom: thin solid #ededed;
  align-items: center !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  padding: 6px 12px !important;
}

::-webkit-scrollbar {
  display: block;
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 9px;
}
#sidebar-icon {
  cursor: pointer;
  float: left;
  width: 2%;
  display: none;
}

.response-sidebar {
  float: left;
  width: 15%;
  padding: 8px;
  background-color: white !important;
  border-radius: 10px;
}

.sidebar-rating > * {
  cursor: pointer;
  padding: 7px 0 7px 4px;
  border-radius: 8px;
  font-size: 12px;
}
.sidebar-opinion {
  margin-top: 24px;
}
.sidebar-opinion > * {
  cursor: pointer;
}

@media all and (max-device-width: 1350px) {
  .resultresponsepageWrapper {
    /* width: 80%; */
  }
  .response-sidebar {
    width: 20%;
  }
}

@media all and (max-device-width: 1040px) {
  .response-sidebar {
    width: 25%;
  }
}

@media all and (max-device-width: 732px) {
  .resultresponsepageWrapper {
    width: 100%;
  }
  .response-sidebar {
    display: none;
  }
}
