.settingspageWrapper {
  display: flex;
  flex-direction: column;
  background-color: #eff0f4;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
}
.generalSettingsPageWrapper {
  height: 100%;
  width: 100%;
}
