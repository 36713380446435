.accountNavbarWrapper {
  max-width: 200px !important;
  min-width: 150px !important;
  /* background-color: #060a32; */
  background-color: #e9e6d0;
  color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.accountNavbarWrapper > * {
  min-height: 100px;
}

.navbarMenuWrapper {
  gap: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbarMenuWrapper > * {
  /* border: 1px solid #181c4d !important; */
  /* background-color: #181c4d; */
  border: 1px solid #97905f !important;
  background-color: #97905f;
  border-radius: 8px;
  cursor: pointer;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 41px;
}
.navbarLogoWrapper {
  /* background-color: white; */
}
@media all and (max-device-width: 600px) {
  .accountNavbarWrapper > * {
    min-height: 40px;
  }
  .accountNavbarWrapper {
    max-width: 100vw !important;
    min-width: 100vw !important;
    padding: 8px;
    flex-direction: row;
    justify-content: center;
    height: 60px;
    position: fixed;
    z-index: 10;
    bottom: 0;
  }
  .navbarLogoWrapper {
    display: none;
  }
  .navbarMenuWrapper > * {
    width: 36px;
    height: 36px;
  }
  .navbarMenuWrapper {
    gap: 32px;
    flex-direction: row;
    align-items: center;
  }
  .emptyDivWrapper {
    display: none;
  }
}
