.Mui-disabled {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.question-card .question-card-icons {
  display: none;
}
.question-card:hover .question-card-icons {
  display: flex !important;
}
.question-card {
  position: unset !important;
}
.drawer > div {
  width: 30%;
}
.drawerUpperSectionWrapper {
  padding: 12px !important;
  border-radius: 10px;
}

.drawerLowerSectionWrapper {
  padding: 12px;
  border-radius: 10px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto !important;
  height: 50vh;
}

.shareSurveyColorPickerContainer > .photoshop-picker {
  background-color: #fff;
  position: absolute;
  z-index: 1;
  top: 0;
  left: -24px;
}

.result-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 82vh;
  -ms-overflow-style: none !important;
  overflow: -moz-scrollbars-none !important;
}

.result-scroll::-webkit-scrollbar {
  width: 0 !important;
}

.share-scroll {
  overflow-y: auto;
  height: 82vh;
  -ms-overflow-style: none !important;
  overflow: -moz-scrollbars-none !important;
}
.share-scroll::-webkit-scrollbar {
  width: 0 !important;
}

.MuiCard-root.drawer-button-card {
  cursor: pointer;
  margin-bottom: 8px;
  background: #3a63f3;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker-wrapper input {
  width: 67px;
  margin-top: 8px;
}

.drawer-question-card-title {
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: inherit;
}

.QuestionDrawerButtonWrapper {
  z-index: 1;
  position: fixed !important;
  top: 60px;
  background-color: #060a32 !important;
  color: #fff !important;
  height: 30px;
  min-width: 30px !important;
  left: 5px;
  box-shadow: 1px 12px 4px rgba(0, 0, 0, 0.15);
}

.styled-select::before {
  border-bottom: 0px none !important;
}
.questionTypeIcon {
  height: 20px;
  margin-right: 8px;
}

@media all and (max-device-width: 732px) {
  .share-icons {
    width: 25px;
  }
}
@media all and (max-device-width: 732px) {
  .questionTypeIcon {
    height: 15px;
    margin-right: 4px;
  }
}
