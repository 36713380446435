.resultspageWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px 20px;
}
.resultspageContentWrapper {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  border-radius: 8px;
  flex: 1;
  max-height: 97%;
  display: flex;
}
.resultspageResultWrapper {
  flex: 1;
}
.resultspageResultContentWrapper {
  padding: 0px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.resultspageContentSwitchNavbar {
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.resultpageContentSwitchNavbarButtonGroup {
  display: flex;
  gap: 10px;
}
.resultspageContentSwitchNavbarSingleButton {
  max-width: 120px !important;
  text-transform: none !important;
  max-height: 35px;
}
.resultspageContentSwitchNavbarOptionGroup {
  display: flex;
  gap: 10px;
}
.resultspageContentSwitchNavbarOptionGroupFilterWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.filterButton {
  display: grid;
  place-items: center;
  background: linear-gradient(180deg, #5fa3fb 0%, #7683f8 100%);
  border-radius: 5px;
  transform: rotate(-45deg);
  width: 30px;
  height: 30px;
  min-width: 26.69px !important;
  min-height: 26.69px !important;
  padding: 0 !important;
  box-shadow: none !important;
}
.resultspageOutcomesWrapper {
  width: 100%;
  height: calc(100% - 100px);
  display: grid;
  place-items: center;
  overflow-y: auto;
  padding: 10px;
}
.resultspageOutcomesWrapper::-webkit-scrollbar {
  display: none;
}

@media all and (max-device-width: 600px) {
  .resultspageWrapper {
    padding: 8px;
  }
  .resultpageContentSwitchNavbarButtonGroup {
    flex-direction: column;
    margin-right: 12px;
  }
  .resultspageResultContentWrapper {
    padding: 0;
  }
  .resultspageOutcomesWrapper {
    padding: 0;
  }
}
