.nextPreviousButton {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.nextPreviousButtonText {
  text-transform: none;
  color: #3a63f3;
  font-weight: 700;
  font-size: 1.4rem;
}
.nextPreviousButtonImage {
  width: 10px;
  margin: auto;
}
