.addfeaturemodalForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 20px;
}
.addfeaturemodalForm > input[type="text"] {
  width: 300px;
  height: 40px;
  border: none;
  border-radius: 5px;
  padding: 5px;
}
