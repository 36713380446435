.basic-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500;
  background-color: #fff;
  box-shadow: 24;
  padding: 24px;
}

#login-modal-description {
  width: 70%;
  height: 90%;
  padding: 0;
}

#register-modal-description {
  width: 70%;
  height: 90%;
  padding: 0;
}

.login-modal {
  padding: 64px 86px 24px 89px;
}

.login-modal-image {
  width: 100%;
  background-color: #f5f6ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addMessageTemplateVariable {
  background-color: #eff4ff !important;
  border-color: #eff4ff !important;
  box-shadow: none !important;
  color: #3a63f3 !important;
  font-size: 12px !important;
  height: 30px !important;
}

@media all and (max-device-width: 932px) {
  #login-modal-description {
    width: 80%;
    height: 70%;
    padding: 0;
  }
  #register-modal-description {
    width: 80%;
    height: auto;
    padding: 0;
  }

  .login-modal-image {
    display: none;
  }
}
@media all and (max-device-width: 732px) {
  .login-modal-image {
    display: none;
  }
  .login-modal {
    padding: 64px 12px 24px 12px;
  }
}
