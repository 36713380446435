.progressbarWrapper {
  width: 100%;
  max-width: 350px;
  border-radius: 20px;
  min-height: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  background-color: rgba(58, 99, 243, 0.39);
  position: relative;
}
.progressBar {
  height: 100% !important;
  border-radius: 15px;
  background: linear-gradient(90deg, #0e9cae 4.33%, #91e676 91%);
  background: #3a63f3;
  transition: all 1s ease 0s;
}
.startCaption,
.endCaption {
  position: absolute;
  bottom: -30px;
  color: #3f3d56;
  font-weight: 800;
}
.startCaption {
  left: 20px;
}
.endCaption {
  right: 20px;
}
@media all and (max-width: 900px) {
  .progressbarWrapper {
    width: 100%;
    max-width: none;
  }
}
