.adminAppbar {
  display: flex;
  flex-direction: row !important ;
  justify-content: space-between;
  padding: 10px;
  border-radius: 0px;
  background-color: rgba(6, 10, 50, 0.72) !important;
}
.adminAppbar > div {
  display: flex;
  gap: 20px;
}
.adminAppbar > img {
  width: 100px;
}
