.adminDashboardWrapper {
  display: flex;
  flex-direction: column;
  max-height: 100vh !important;
  overflow: hidden;
  padding: 0px;
}
.adminDashboardComponentWrapper {
  width: 100vw;
  height: 100%;
  overflow-y: auto;
}
