/* .css-1a1fmpi-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
} */
.css-1e877za-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
  border-bottom: none !important;
}
/* .css-1e877za-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
} */
.css-1e877za-MuiInputBase-root-MuiInput-root:after {
  border-bottom: none !important;
}

.css-1ds0grq-MuiInputBase-root-MuiInput-root {
  font-size: 1.3rem !important;
}

.css-1ds0grq-MuiInputBase-root-MuiInput-root::placeholder,
.css-1ds0grq-MuiInputBase-root-MuiInput-root::placeholder,
.css-1ds0grq-MuiInputBase-root-MuiInput-root:-ms-input-placeholder,
.css-1ds0grq-MuiInputBase-root-MuiInput-root:-ms-input-placeholder {
  color: #000 !important;
}

.css-1ds0grq-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
}
.css-1ds0grq-MuiInputBase-root-MuiInput-root:after {
  border-bottom: none !important;
}
.css-1atc8ut-MuiSlider-root {
  margin-bottom: 10px !important;
  padding: 0 0 14px 0 !important;
}
.css-mfxiry-MuiSlider-markLabel {
  top: 17px !important;
}
.css-1f59m3i-MuiSlider-markLabel {
  top: 17px !important;
}

.save-button:hover,
.preview-button:hover {
  background-color: #fff !important;
}

.save-button,
.preview-button {
  font-size: 1.25rem !important;
  text-transform: none !important;
  width: 110px !important;
}

.question-field fieldset::placeholder {
  color: #000 !important;
}

.question-field fieldset::before {
  border-bottom: none !important;
}

.question-field fieldset {
  border: none !important;
}
.question-field fieldset::after {
  border-bottom: none !important;
}

.question-field-section {
  padding-left: 63px;
  padding-right: 63px;
  box-sizing: border-box;
  /* background-color: black; */
  height: -moz-available, -webkit-fill-available;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-slot {
  background-color: #d9d9d9 !important;
  border-radius: 5px;
}

.image-slot input[type="file"] {
  display: none;
}

.image-slot input[type="file"] + label {
  display: inline-block;
}
.change-photo-icon {
  background-color: white;
  bottom: 8px;
  right: 8px;
  border-radius: 50px !important;
  opacity: 0.44;
}
.delete-photo-icon {
  background-color: white;
  top: 8px;
  right: 8px;
  border-radius: 50px !important;
  opacity: 0.44;
}

.options {
  width: 50% !important;
}

.options .option-icons {
  display: none;
}
.options:hover .option-icons {
  display: flex !important;
}
.options {
  position: unset !important;
}
.savePreviewButtonWrapper {
  min-width: 20%;
  display: flex;
  position: static;
  align-items: center;
  padding-top: 24px;
  justify-content: space-between;
}

@media all and (max-device-width: 932px) {
  .question-field-section {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media all and (max-device-width: 736px) {
  .question-field-section {
    padding-left: 12px;
    padding-right: 12px;
  }
  .options {
    width: 100% !important;
  }
}
@media all and (max-device-width: 600px) {
  .save-button,
  .preview-button {
    min-height: 25px;
    width: 70px !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
  }
  .savePreviewButtonWrapper {
    justify-content: center;
  }
}
