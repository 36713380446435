@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=MuseoModerno:wght@300;400;700&display=swap");
:root {
  font-size: 16px;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-y: hidden; */
}
* {
  padding: 0;
  margin: 0;
}

code {
  font-family: "Poppins", sans-serif !important;
}

@media all and (max-device-width: 932px) {
  :root {
    font-size: 14px;
  }
  body {
    overflow-y: auto;
  }
}
@media all and (max-device-width: 732) {
  :root {
    font-size: 12px;
  }
}
::placeholder {
  color: black !important;
}
.recharts-legend-item-text {
  color: black !important;
}
