.messageviewWrapper {
  width: 100%;
  height: 100%;
  padding: 100px;
}
.messageviewWrapper > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}
.messageviewWrapper > div > div {
  display: flex;
  align-items: center;
  gap: 20px;
}
.messageviewWrapper > div > div > div {
  display: flex;
  flex-direction: column;
}
.messageviewWrapper > div > div > div > span:nth-child(1) {
  color: #060a32;
  font-size: 20px;
  font-weight: 900;
}
.messageviewWrapper > div > div > div > span:nth-child(2) {
  color: grey;
  font-size: 10px;
}
.messageViewInnerWrapper {
  margin-left: 90px;
}
