.shareSurveyWrapper {
  padding: 30px 65px 8px 65px;
}

.shareSurveyUpperCardWrapper {
  border-radius: 7px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: none !important;
}
.linkWrapper {
  padding: 16px 36px;
  min-width: 50%;
}

.urlTextField fieldset {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 40px;
}

.urlTextField {
  height: 40px;
  width: 50%;
}

.shareWrapper {
  border: 1px solid #3a63f3;
  border-radius: 10px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 40%;
}

.share-icons {
  width: 25px;
}

.shareCard {
  width: 60px;
  height: 43px;
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.shareSurveyLowerCardWrapper {
  padding: 10px;
  /* overflowY: "auto", */
  flex: 1 1 auto;
  place-items: center;
  background: #ffffff;
  border-radius: 7px;
  margin: 12px 0 0 0 !important;
  width: 100% !important;
}

.codeGeneratorWrapper {
  height: 100%;
  justify-content: space-evenly;
  display: flex !important;
  flex-direction: column !important;
  gap: 30px;
  padding: 10px;
}

.customiseQRCodeWrapper {
  border: 1px dashed #3a63f3;
  border-radius: 10px;
  width: 216px;
  height: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.customiseQRCodeWrapper > div:nth-child(1) {
  flex: 0.8;
  height: 126px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.customiseQRCodeWrapper > div:nth-child(2) {
  flex: 0.2;
  display: grid;
  place-items: center;
}

.shareSurveyColorPickerContainer > .photoshop-picker {
  background-color: #fff;
  position: absolute;
  z-index: 1;
  top: 0;
  left: -24px;
}

.QrCodeCard {
  padding: 12px;
  border: 1 px solid rgba(0, 0, 0, 0.15);
  width: 100%;
  box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  height: 92%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  /* background: radial-gradient(
      circle 200px at 150px 150px,
      rgba(9, 56, 223, 0.4),
      rgba(21, 21, 53, 0.5)
    )
    top left !important; */
  background-color: transparent !important;
  backdrop-filter: blur(10px);
  border-radius: 20px;
}

@media all and (max-device-width: 1200px) {
  .shareSurveyUpperCardWrapper {
    min-width: 60%;
  }
  .shareWrapper {
    min-width: 30%;
  }
}

@media all and (max-device-width: 932px) {
  .shareSurveyUpperCardWrapper {
    display: block;
  }
  .linkWrapper {
    padding: 12px;
  }
}

@media all and (max-device-width: 600px) {
  .shareSurveyWrapper {
    padding: 16px;
  }
  .linkWrapper {
    padding: 8px;
  }
  .shareWrapper {
    padding: 8px;
  }
  .shareCard {
    padding: 8px;
    width: 40px;
  }
  .shareSurveyLowerCardWrapper {
    height: 100%;
  }
}
