.planmanageWrapper {
  display: flex;
  height: calc(100vh - 10px - 60px);
}
.planmanageInnerWrapper {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.planmanageInnerWrapper > div:nth-child(1) {
  display: flex;
  justify-content: flex-end;
}
.planCardsWrapper {
  height: 100%;
  /* background-color: red; */
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px;
  overflow-y: auto;
}
