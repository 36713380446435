.optionCardWrapper {
  position: relative;
  width: 97%;
  min-height: 320px;
  max-height: 320px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 12px;
}
.optionCardQuestionId {
  position: absolute;
  bottom: 5px;
  right: 20px;
  background: white;
  padding: 2px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  color: #9cafb8;
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.addNextQuestionButton {
  margin-left: 24px;
  font-size: 1rem !important;
  padding: 6px 16px;
}

@media all and (max-device-width: 600px) {
  .optionCardWrapper {
    width: 92%;
    min-height: 300px;
  }
  .addNextQuestionButton {
    margin-left: 0;
    font-size: 0.8rem !important;
    padding: 2px !important;
  }
}
